import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  content: {
    width: '100%',
    padding: spacing(32, 50),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 1000,
    display: 'grid',
    gap: spacing(40, 50),
  },
  sectionTitle: {
    fontSize: spacing(22),
    fontWeight: 500,
    lineHeight: spacing(20),
    color: Theme.Colors.Primary.Base,
  },
  detailLabel: {
    fontSize: spacing(10.5),
    color: '#9F9F9F',
  },
  supportIcon: {
    objectFit: 'contain',
    fontSize: '2rem',
  },
  buttonSupportIcon: {
    marginLeft: spacing(6),
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  required: {
    color: Theme.Colors.Red.Base,
    marginLeft: spacing(4),
  },
}))
